exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alati-aluminijum-jsx": () => import("./../../../src/pages/alati/aluminijum.jsx" /* webpackChunkName: "component---src-pages-alati-aluminijum-jsx" */),
  "component---src-pages-alati-bakelit-jsx": () => import("./../../../src/pages/alati/bakelit.jsx" /* webpackChunkName: "component---src-pages-alati-bakelit-jsx" */),
  "component---src-pages-alati-delovi-jsx": () => import("./../../../src/pages/alati/delovi.jsx" /* webpackChunkName: "component---src-pages-alati-delovi-jsx" */),
  "component---src-pages-alati-ekstruzija-jsx": () => import("./../../../src/pages/alati/ekstruzija.jsx" /* webpackChunkName: "component---src-pages-alati-ekstruzija-jsx" */),
  "component---src-pages-alati-guma-jsx": () => import("./../../../src/pages/alati/guma.jsx" /* webpackChunkName: "component---src-pages-alati-guma-jsx" */),
  "component---src-pages-alati-lim-jsx": () => import("./../../../src/pages/alati/lim.jsx" /* webpackChunkName: "component---src-pages-alati-lim-jsx" */),
  "component---src-pages-alati-plastika-jsx": () => import("./../../../src/pages/alati/plastika.jsx" /* webpackChunkName: "component---src-pages-alati-plastika-jsx" */),
  "component---src-pages-aufbau-jsx": () => import("./../../../src/pages/aufbau.jsx" /* webpackChunkName: "component---src-pages-aufbau-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-jsx": () => import("./../../../src/pages/kontakt.jsx" /* webpackChunkName: "component---src-pages-kontakt-jsx" */),
  "component---src-pages-o-nama-jsx": () => import("./../../../src/pages/o_nama.jsx" /* webpackChunkName: "component---src-pages-o-nama-jsx" */),
  "component---src-pages-proizvodi-jsx": () => import("./../../../src/pages/proizvodi.jsx" /* webpackChunkName: "component---src-pages-proizvodi-jsx" */),
  "component---src-pages-reference-jsx": () => import("./../../../src/pages/reference.jsx" /* webpackChunkName: "component---src-pages-reference-jsx" */),
  "component---src-pages-usmerivac-vazduha-jsx": () => import("./../../../src/pages/usmerivac_vazduha.jsx" /* webpackChunkName: "component---src-pages-usmerivac-vazduha-jsx" */)
}

